import { observer } from 'mobx-react';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import LoadingContent from 'components/content/LoadingContent/LoadingContent';
import GenderSelector from 'components/GenderSelector/GenderSelector';
import OptionSelector from 'components/OptionSelector/OptionSelector';
import StarRating from 'components/StarRating/StarRating';
import ButtonWithIcon from 'components/UI/ButtonWithIcon/ButtonWithIcon';
import Skeleton from 'components/UI/Skeleton/Skeleton';
import ErrorContent from 'components/content/ErrorContent/ErrorContent';
import InformativeContent from 'components/content/InformativeContent/InformativeContent';
import ResultHeader from 'components/content/ResultHeader/ResultHeader';
// import MarketsSelector from 'components/MarketsSelector/MarketsSelector';
import SeoContent from 'components/content/SeoContent/SeoContent';
// import ServicesContent from 'components/content/ServicesContent/ServicesContent';

import { useMatchMedia } from 'utils/hooks/use-match-media';

import create from 'assets/images/create-button.svg';
import repeat from 'assets/images/repeat-button.svg';
import sendDis from 'assets/images/sendDis-button.svg';
import send from 'assets/images/send.svg';

import './Main.scss';

const REVIEW_PRICE_AVITO = 250;
const REVIEW_PRICE_YANDEX_MAPS = 350;

const Main = observer(({ store }) => {
  const { isMobile } = useMatchMedia();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  /* const [showServicesContent, setShowServicesContent] = useState(true); */
  const currentMarketplace = pathname.includes('ai-otzyv-obyavleniya') ? 'avito' : 'yandex';

  const productInputRef = useRef(null);
  const commentInputRef = useRef(null);
  const reviewResultRef = useRef(null);
  const emotionsSelectorRef = useRef(null);
  const characteristicsSelectorRef = useRef(null);

  const formattedText = store.reviewResult
    .split('\n\n')
    .map((paragraph, index) => (
      <p key={index} style={{ marginBottom: '12px' }}>
        {paragraph}
      </p>
    ));

  const scrollToRef = (ref, block = 'start') => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block,
      });
    }
  };

  const handleGenerateReview = () => {
    if (!store.validateFields()) {
      if (store.emotionsError) scrollToRef(emotionsSelectorRef);
      if (store.characteristicsError) scrollToRef(characteristicsSelectorRef);
      if (store.productError) scrollToRef(productInputRef);
      if (store.commentError) scrollToRef(commentInputRef);
      return;
    }

    setIsLoading(true);
    scrollToRef(reviewResultRef);
    store.generateReview().finally(() => {
      setIsLoading(false);
    });
  };

  const handleRandomFill = () => {
    store.randomFill();
    if (store.productError) scrollToRef(productInputRef, 'center');
  };

  const renderResultContent = () => {
    if (isLoading) return <> <Skeleton /> <LoadingContent /> </>
    if (store.reviewResultError) return <> <Skeleton /> <ErrorContent repeatRequest={handleGenerateReview} errorMessage={store.reviewResultError} /> </>
    if (store.reviewResult) return <div className='main_result-generation-content-review'>{formattedText}</div>

    return <InformativeContent randomFill={handleRandomFill} />;
  };

  const publishReview = () => {
    const reviewParam = btoa(encodeURIComponent(store.reviewResult));
    const url = `${store.publishUrl}?review-text=${reviewParam}`;
    store.handleRedirect(url);
  }

  /* useEffect(() => {
    if (window.location.hash === '#settings') {
      setShowServicesContent(false);
    }
  }, []); */

  return (
    <main className='main'>
      <div className='main__inner'>
        {/* <MarketsSelector store={store} /> */}
        {isMobile &&
          <div className='main__settings-star'>
            <p className='main__settings-star-title'>Начните с выбора оценки</p>
            <StarRating value={store.stars} onChange={(stars) => store.setStars(stars)} />
          </div>
        }
        {/* {showServicesContent && <ServicesContent store={store} isMobile={isMobile} />}  */}     
        <div className='main__container'>
          <div className='main__settings'>
            <div className='main__settings-rating-container'>
              {!isMobile && <StarRating value={store.stars} onChange={(stars) => store.setStars(stars)} />}
              <div className='main__settings-rating-container-others'>
                <OptionSelector
                  title='Выберите эмоции для отзыва'
                  options={store.emotionOptions}
                  selected={store.emotions}
                  onToggle={(emotion) => store.toggleEmotion(emotion)}
                  maxSelect={3}
                  error={store.emotionsError}
                  selectorRef={emotionsSelectorRef}
                />
                <OptionSelector
                  title='Характеристики для отзыва'
                  options={store.characteristicOptions}
                  selected={store.characteristics}
                  onToggle={(characteristic) => store.toggleCharacteristic(characteristic)}
                  maxSelect={3}
                  error={store.characteristicsError}
                  selectorRef={characteristicsSelectorRef}
                />
              </div>
            </div>

            <div className='main__settings-product'>
              <div ref={productInputRef}>
                <p className='main__settings-label'>
                  О каком продукте или услуге идет речь?
                </p>
                {store.productError && <p className='main__settings-product-error'>{store.productError}</p>}
                <input
                  className={store.productError ? 'error' : ''}
                  type='text'
                  maxLength={150}
                  placeholder='Компания или услуга, на которую пишем отзыв'
                  value={store.product}
                  onChange={(e) => store.setProduct(e.target.value)}
                />
              </div>
              <div ref={commentInputRef}>
                <p className='main__settings-label'>Что важно упомянуть? </p>
                {store.commentError && <p className='main__settings-product-error'>{store.commentError}</p>}
                <input
                  className={store.commentError ? 'error' : ''}
                  type='text'
                  minLength={15}
                  maxLength={450}
                  placeholder='Детали, имена, факты, которые нужно отразить'
                  value={store.comment}
                  onChange={(e) => store.setComment(e.target.value)}
                />
              </div>

              <div className='main__settings-product-write-reviews'>
                <p>Кто пишет отзыв?</p>
                <GenderSelector store={store} />
              </div>
            </div>

            <ButtonWithIcon
              title='Создать отзыв'
              className='main__settings-create-button'
              icon={create}
              onClick={handleGenerateReview}
              ariaLabel='Создать отзыв'
            />
          </div>

          <div className='main_result' ref={reviewResultRef}>
            <div className='main_result__container'>
              <ResultHeader store={store} isMobile={isMobile} />

              <div className='main_result-generation'>
                <div className='main_result-generation-content'>
                  {renderResultContent()}
                </div>

                <div className='main_result-generation-buttons'>
                  <p className='main_result-generation-buttons-description'>
                    Иногда нужно несколько попыток, чтобы вам понравился отзыв.
                    <br />
                    Нажмите "Ещё вариант", чтобы получить другой вариант.
                  </p>
                  <div className='main_result-generation-buttons__container'>
                    <div className='main_result-generation-function-buttons'>
                      <ButtonWithIcon
                        title={`Ещё\u00a0вариант`}
                        className='main_result-generation-repeat-button'
                        icon={repeat}
                        onClick={handleGenerateReview}
                        ariaLabel='Повторить генерацию отзыва'
                        disabled={!store.reviewResult}
                      />
                    </div>
                    {!isMobile && (
                      <ButtonWithIcon
                        title={`Опубликовать от ${currentMarketplace === 'yandex' ? REVIEW_PRICE_YANDEX_MAPS : REVIEW_PRICE_AVITO}\u00a0₽`}
                        className='main_result-generation-post-button'
                        icon={!store.reviewResult ? sendDis : send}
                        onClick={publishReview}
                        ariaLabel={`Опубликовать сгенерированный отзыв от ${currentMarketplace === 'yandex' ? REVIEW_PRICE_YANDEX_MAPS : REVIEW_PRICE_AVITO}\u00a0₽`}
                        disabled={!store.reviewResult}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isMobile && (
            <ButtonWithIcon
              title={`Опубликовать от ${currentMarketplace === 'yandex' ? REVIEW_PRICE_YANDEX_MAPS : REVIEW_PRICE_AVITO}\u00a0₽`}
              className='main_result-generation-post-button'
              icon={!store.reviewResult ? sendDis : send}
              onClick={publishReview}
              ariaLabel={`Опубликовать сгенерированный отзыв от ${currentMarketplace === 'yandex' ? REVIEW_PRICE_YANDEX_MAPS : REVIEW_PRICE_AVITO}\u00a0₽`}
              disabled={!store.reviewResult}
            />
          )}
        </div>
      </div>
      <SeoContent store={store} />
    </main>
  );
});

export default Main;
